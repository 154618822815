import { Injectable } from '@angular/core';
import { LoadingService } from '../services/loading.service';
import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root',
})
export class ErrorHandlerService {
	constructor(
		private loadingService: LoadingService,
		private authService: AuthService,
	) {}

	handleHttpError(error: any): void {
		console.error('Error occurred:', error);
		const errorCode = error?.error?.error?.error_code;
		this.loadingService.hide();
		this.loadingService.hideCustom();
		if (!localStorage.getItem('access_token') || errorCode === 401) {
			this.authService.logout();
		}
	}

	handleGeneralError(error: any): void {
		console.error('General error occurred:', error);
	}
}
