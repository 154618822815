import { Component, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { ILoading } from './core/models/loading.models';
import { IModalConfirmation } from './core/models/modal-confirmation.models';
import { ISnackbar } from './core/models/snackbar.models';
import { DialogConfirmationService } from './core/services/dialog-confirmation.service';
import { LanguageService } from './core/services/language.service';
import { LoadingService } from './core/services/loading.service';
import { SnackbarService } from './core/services/snackbar.service';
import * as moment from 'moment';
import { AuthService } from './core/services/auth.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	title = 'onivia';
	public loading: Observable<ILoading>;
	public customLoading: Observable<ILoading>;
	public snackbar: Observable<ISnackbar>;
	public modalConfirmation: Observable<IModalConfirmation>;
	public ultimoBlur = null;

	constructor(
		private authService: AuthService,
		private languageService: LanguageService,
		private loadingService: LoadingService,
		public snackbarService: SnackbarService,
		public dialogConfirmationService: DialogConfirmationService,
	) {
		this.languageService.autoResolveLanguage();
		this.loading = this.loadingService.getShowLoading();
		this.customLoading = this.loadingService.getShowCustomLoading();
		this.snackbar = this.snackbarService.getSnackBar();
		this.modalConfirmation = this.dialogConfirmationService.getDialogConfirmation();
	}

	@HostListener('window:focus', ['$event'])
	onFocus(): void {
		if (this.ultimoBlur !== null && this.ultimoBlur._isValid) {
			this.ultimoBlur = moment().diff(this.ultimoBlur) / 60000;
			console.warn(this.ultimoBlur);
			if (this.ultimoBlur >= 50) {
				this.authService.logout();
			}
		}
	}

	@HostListener('window:blur', ['$event'])
	onBlur(): void {
		this.ultimoBlur = moment();
	}
}
